import styled from '@emotion/styled'
import { Box, Button, Typography, TableCell, TextField, IconButton } from '@mui/material'

export const MainContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f4f6f8'
})

export const Container = styled(Box)({
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    padding: '14px',
    borderRadius: '8px',
    boxShadow: '0px 1px 12px 0px rgba(209,209,209,1)'
})

export const Title = styled(Typography)({
    fontWeight: 'bold',
    marginBottom: '12px'
})
export const FieldInfo = styled(TextField)({
    width: '316px',
    marginTop: '16px',
    '& .MuiInputBase-root': {
        height: '40px'
    },
    '& .MuiFormLabel-root': {
        top: '-6px'
    }
})
export const FieldContainer = styled('div')({
    // width: '550px',
    marginTop: '12px',
    display: 'flex',
    justifyContent: 'space-between'
})

export const FieldName = styled(Typography)({
    width: '300px',
    height: '40px',
    marginRight: '20px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px 0 12px',
    borderRadius: '4px',
    color: 'rgba(0, 0, 0, 0.6)'
})

export const AttachButton = styled(IconButton)({
    border: '1px solid #1976d2',
    boxShadow: '0px 1px 6px 0px rgba(209,209,209,1)'
})

export const SendButton = styled(Button)({
    width: '120px',
    marginTop: '20px'
})