import { Typography } from '@mui/material';
import { AttachFileOutlined } from '@mui/icons-material'
import { useEffect, useRef, useState } from 'react';
import { AttachButton, Container, FieldContainer, FieldInfo, FieldName, MainContainer, SendButton, Title } from './styles';
import { AlertDialog } from '../AlertDialog';
import { postForm } from '../../services/formServices';

const initialFile = {
    name: '',
    base64Data: ''
}



const initialDialog = {
    title: '',
    content: '',
    btnTxt: '',
    funcBtn: () => { },
    open: false,
    onClose: () => { }
}

const SITE_KEY = '6LftY-4ZAAAAAN6id-q4pryn_FYVa7Kb6eAdpmwQ'
const Form = () => {
    let nitLocal = localStorage.getItem('nit')
    let emailLocal = localStorage.getItem('email')
    const initialForm = {
        nit: nitLocal,
        email: emailLocal
    }
    const [file, setFile] = useState(initialFile)
    const [form, setForm] = useState(initialForm)
    const [isValidEmail, setIsValidEmail] = useState(null)
    const [dialogInfo, setDialogInfo] = useState(initialDialog)

    const filePdf = useRef(null)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const updateForm = ({ param, value }) => {
        if (param === 'email') {
            if (value !== '') {
                setIsValidEmail(emailRegex.test(value));
            } else {
                setIsValidEmail(null)
            }
        }
        setForm(prevForm => ({ ...prevForm, [param]: value }))
    }

    const handleSelectFile = () => {
        filePdf.current.value = null
        filePdf.current.click()
    }

    function fileToBase64(file, callback) {
        const reader = new FileReader();

        reader.onload = (event) => {
            const base64Data = event.target.result.split(',')[1];
            callback(base64Data);
        };

        reader.readAsDataURL(file);
    }

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            fileToBase64(selectedFile, (base64Data) => {
                setFile({
                    name: selectedFile.name,
                    base64Data
                })
            });
        }
    }

    const onCloseAlert = () => {
        setDialogInfo(initialDialog)
    }

    const loadScriptByURL = (id, url, callback) => {
        const isScriptExist = document.getElementById(id);
        if (!isScriptExist) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = url;
            script.id = id;
            script.onload = () => {
                if (callback) callback();
            };
            document.body.appendChild(script);
        }
        if (isScriptExist && callback) callback();
    };

    const getCaptcha = () => {
        window.grecaptcha.ready(() => {
            (async () => {
                try {
                    const token = await window.grecaptcha.execute(`${SITE_KEY}`, { action: 'submit' });
                    const body = {
                        nit: form.nit,
                        correo: form.email,
                        archivo: `data:application/zip;base64,${file.base64Data}`,
                        captcha: token
                    };

                    const { success } = await postForm({ body })
                    if (success === 1) {
                        setFile(initialFile)
                        setDialogInfo({ title: '¡Enviado!', content: 'Los archivos fueron enviados y ahora se encuentran pendientes por revisión', btnTxt: 'Aceptar', funcBtn: onCloseAlert, open: true, onClose: onCloseAlert })
                    } else {
                        setDialogInfo({ title: 'Falló', content: 'Hay inconsistencias en los archivos enviados', btnTxt: 'Aceptar', funcBtn: onCloseAlert, open: true, onClose: onCloseAlert })
                    }
                } catch (error) {
                    console.error(error);
                }
            })();
        });
    };

    const onSubmit = async () => {
        const nit = form.nit
        const email = form.email
        localStorage.setItem('nit', nit)
        localStorage.setItem('email', email)
        setForm({ nit, email })
        getCaptcha()
    }

    useEffect(() => {
        loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, () => {
        });
    }, []);

    return (
        <>
            <AlertDialog
                title={dialogInfo.title}
                content={dialogInfo.content}
                btnTxt={dialogInfo.btnTxt}
                funcBtn={dialogInfo.funcBtn}
                open={dialogInfo.open}
                onClose={dialogInfo.onClose}
            />
            <MainContainer>
                <Container>
                    <Title>Formulario</Title>
                    <Typography>Ingresa tu NIT, correo y adjunta tu factura en un archivo ZIP</Typography>
                    <FieldInfo
                        label='NIT'
                        value={form.nit}
                        onChange={(e) => updateForm({ param: 'nit', value: e.target.value })}
                    />
                    <FieldInfo
                        label='Correo'
                        type='email'
                        value={form.email}
                        error={!isValidEmail && isValidEmail !== null}
                        helperText={!isValidEmail && isValidEmail !== null && 'Email inválido'}
                        onChange={(e) => updateForm({ param: 'email', value: e.target.value })}
                    />

                    <FieldContainer>
                        <input
                            id='file'
                            type='file'
                            accept='.zip'
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            ref={filePdf}
                        />
                        <FieldName>
                            {file.name !== '' ? file.name : 'Adjuntar archivo .zip'}
                        </FieldName>
                        <AttachButton
                            onClick={handleSelectFile}
                        >
                            <AttachFileOutlined />
                        </AttachButton>
                    </FieldContainer>
                    <SendButton
                        disabled={!file.name || !form.nit || !form.email}
                        variant='contained'
                        onClick={onSubmit}
                    >
                        Enviar
                    </SendButton>
                </Container>
            </MainContainer>
        </>
    );
}

export { Form }
