
import React from 'react'
import { Form } from './components';

function App() {

  return (
    <Form />
  );
}

export default App;
