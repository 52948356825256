export const postForm = async ({ body }) => {
    try {
        const response = await fetch('https://facturacion.appengine.lucia.com.co/facturas/almacenar', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        if (!response.status === 200) {
            throw new Error('La solicitud no fue exitosa');
        }
        const data = await response.json();
        console.log('data ok', data)
        return data
    } catch (error) {
        console.error(error);
    }
};
