import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import React from 'react'

const AlertDialog = ({ title, content, btnTxt, funcBtn, open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent style={{ width: '400px' }}>
                <DialogTitle style={{ padding: '0 0 12px 0' }}>{title}</DialogTitle>
                <Typography>{content}</Typography>
                <div style={{ position: 'relative', height: '60px' }}>
                    <Button onClick={funcBtn} variant='outlined' style={{ position: 'absolute', right: '0', bottom: '0' }} >{btnTxt}</Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export { AlertDialog }